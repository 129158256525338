//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchTurnoverPayeeList, addTurnoverPayee, updateTurnoverPayee, delTurnoverPayee } from "@/api/finance";
import { fetchMemberList } from "@/api/member";
import { fetchPropertyList } from "@/api/charge";
import { billMode } from "@/utils/global.js";
import { transBillState, downloadFun } from "@/utils/util";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
export default {
  name: "orderList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          status: ""
        }
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      totalNum: 0,
      total: 0,
      agentLoading: false,
      agentOptions: [],
      dialogForm: {
        property_id: '',
        property_name: '',
        member_id: '',
        member_realname: '',
        member_mobile: '',
        settle_ratio: '',
        settle_cycle: 'month',
        withdraw_ratio: 0.6,
        //提现手续费
        settle_type: 0,
        //0=手动结束，1=自动结算
        bank_no: '',
        //收款方银行卡号	
        enc_true_name: '',
        //收款方用户名	
        bank_code: '',
        //收款方开户行	
        status: true,
        remark: ''
      },
      payeeDialog: false,
      dialogTitle: '',
      textMap: {
        update: "编辑收款人",
        create: "添加收款人"
      },
      //搜索用户
      memberLoading: false,
      memberOptions: [],
      submitLoading: false,
      //搜索小区
      propertyLoading: false,
      propertyOptions: [],
      rules: {
        property_id: [{
          required: true,
          message: "小区必填",
          trigger: "blur"
        }],
        member_id: [{
          required: true,
          message: "用户必填",
          trigger: "blur"
        }],
        settle_ratio: [{
          required: true,
          message: "结算费率必填",
          trigger: "blur"
        }],
        settle_type: [{
          required: true,
          message: "结算方式必选",
          trigger: "blur"
        }],
        enc_true_name: [{
          required: true,
          message: "收款人姓名必填",
          trigger: "blur"
        }]
      },
      //结算方式
      settleTypeMode: [{
        value: 0,
        label: "手动结算"
      }, {
        value: 1,
        label: "自动结算"
      }, {
        value: 2,
        label: "线下结算"
      }],
      settleCycleMode: [{
        value: 'day',
        label: "日结"
      }, {
        value: 'week',
        label: "周结"
      }, {
        value: 'month',
        label: "月结"
      }],
      bankCodeList: [{
        label: "请选择银行",
        value: 0
      }, {
        label: "工商银行",
        value: 1002
      }, {
        label: "农业银行",
        value: 1005
      }, {
        label: "建设银行",
        value: 1003
      }, {
        label: "中国银行",
        value: 1026
      }, {
        label: "交通银行",
        value: 1020
      }, {
        label: "招商银行",
        value: 1001
      }, {
        label: "邮储银行",
        value: 1066
      }, {
        label: "民生银行",
        value: 1006
      }, {
        label: "平安银行",
        value: 1010
      }, {
        label: "中信银行",
        value: 1021
      }, {
        label: "浦发银行",
        value: 1004
      }, {
        label: "兴业银行",
        value: 1009
      }, {
        label: "光大银行",
        value: 1022
      }, {
        label: "广发银行",
        value: 1027
      }, {
        label: "华夏银行",
        value: 1025
      }, {
        label: "宁波银行",
        value: 1056
      }, {
        label: "北京银行",
        value: 4836
      }, {
        label: "上海银行",
        value: 1024
      }, {
        label: "南京银行",
        value: 1054
      }, {
        label: "长子县融汇村镇银行",
        value: 4755
      }, {
        label: "长沙银行",
        value: 4216
      }, {
        label: "浙江泰隆商业银行",
        value: 4051
      }, {
        label: "中原银行",
        value: 4753
      }, {
        label: "企业银行（中国）",
        value: 4761
      }, {
        label: "顺德农商银行",
        value: 4036
      }, {
        label: "衡水银行",
        value: 4752
      }, {
        label: "长治银行",
        value: 4756
      }, {
        label: "大同银行",
        value: 4767
      }, {
        label: "河南省农村信用社",
        value: 4115
      }, {
        label: "宁夏黄河农村商业银行",
        value: 4150
      }, {
        label: "山西省农村信用社",
        value: 4156
      }, {
        label: "安徽省农村信用社",
        value: 4166
      }, {
        label: "甘肃省农村信用社",
        value: 4157
      }, {
        label: "天津农村商业银行",
        value: 4153
      }, {
        label: "广西壮族自治区农村信用社",
        value: 4113
      }, {
        label: "陕西省农村信用社",
        value: 4108
      }, {
        label: "深圳农村商业银行",
        value: 4076
      }, {
        label: "宁波鄞州农村商业银行",
        value: 4052
      }, {
        label: "浙江省农村信用社联合社",
        value: 4764
      }, {
        label: "江苏省农村信用社联合社",
        value: 4217
      }, {
        label: "江苏紫金农村商业银行股份有限公司",
        value: 4072
      }, {
        label: "北京中关村银行股份有限公司",
        value: 4769
      }, {
        label: "星展银行（中国）有限公司",
        value: 4778
      }, {
        label: "枣庄银行股份有限公司",
        value: 4766
      }, {
        label: "海口联合农村商业银行股份有限公司",
        value: 4758
      }, {
        label: "南洋商业银行（中国）有限公司",
        value: 4763
      }]
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchTurnoverPayeeList(data).then(function (response) {
        console.log(response);
        _this.totalNum = response.meta.total_num;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.total = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    resetPayee: function resetPayee() {
      this.dialogForm = {
        property_id: '',
        property_name: '',
        member_id: '',
        member_realname: '',
        member_mobile: '',
        settle_ratio: '',
        settle_cycle: 'month',
        withdraw_ratio: 0.6,
        //提现手续费
        settle_type: 0,
        bank_code: '',
        bank_no: '',
        enc_true_name: '',
        status: true,
        remark: ''
      };
    },
    handleAdd: function handleAdd() {
      var _this2 = this;

      this.resetPayee();
      this.payeeDialog = true;
      this.dialogTitle = "create";
      this.$nextTick(function () {
        _this2.$refs["dialogForm"].clearValidate();
      });
    },
    handleEdit: function handleEdit(row) {
      var data = Object.assign({}, row);
      this.dialogTitle = "update";
      this.payeeDialog = true;
      this.resetPayee();
      this.dialogForm = {
        id: data.id,
        property_id: data.property_id,
        property_name: data.property_name,
        member_id: data.member_id,
        member_realname: data.member_realname,
        member_mobile: data.member_mobile,
        settle_ratio: data.settle_ratio,
        settle_cycle: data.settle_cycle,
        withdraw_ratio: data.withdraw_ratio,
        //提现手续费
        settle_type: data.settle_type,
        bank_code: data.bank_code,
        bank_no: data.bank_no,
        enc_true_name: data.enc_true_name,
        status: data.status == 1 ? true : false,
        remark: data.remark
      };
    },
    handleEditBtn: function handleEditBtn() {
      var _this3 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          _this3.submitLoading = true;
          var data = Object.assign({}, _this3.dialogForm);
          updateTurnoverPayee(data).then(function (res) {
            // 成功之后刷新或
            // this.dataList.unshift('返回的数据')
            _this3.$message({
              type: "success",
              message: "编辑成功"
            });

            _this3.payeeDialog = false;
            _this3.submitLoading = false;

            _this3.getTableData();
          }).catch(function (err) {
            _this3.submitLoading = false;

            _this3.$message({
              type: "error",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleDel: function handleDel(row) {
      var _this4 = this;

      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u6536\u6B3E\u4EBA";
      var title = "删除收款人";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var id = row.id;
        delTurnoverPayee(id).then(function (res) {
          console.log(res);

          _this4.$message({
            type: "success",
            message: "删除收款人成功"
          });

          var index = _this4.dataList.indexOf(row);

          _this4.dataList.splice(index, 1);
        }).catch(function (err) {
          _this4.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleSubmit: function handleSubmit() {
      var _this5 = this;

      if (this.submitLoading == true) {
        return;
      }

      this.submitLoading = true;
      addTurnoverPayee(this.dialogForm).then(function (res) {
        console.log(res); // 成功之后刷新或
        // this.dataList.unshift('返回的数据')

        _this5.$message({
          type: "success",
          message: "添加小区收款成功"
        });

        _this5.payeeDialog = false;
        _this5.submitLoading = false;

        _this5.getTableData();
      }).catch(function (err) {
        _this5.submitLoading = false;

        _this5.$message({
          type: "error",
          message: err.data["message"]
        });
      });
      return false;
    },
    //搜索小区
    remoteProperty: function remoteProperty(query) {
      var _this6 = this;

      if (query !== "") {
        this.propertyLoading = true;
        setTimeout(function () {
          _this6.propertyLoading = false;
          var data = {
            keyword: query
          };
          fetchPropertyList(data).then(function (res) {
            console.log(res);
            _this6.propertyOptions = res.data;
          });
        }, 100);
      } else {
        this.propertyOptions = [];
      }
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this7 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this7.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this7.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    SettleType: function SettleType(type) {
      switch (type) {
        case 0:
          return '手动结算';

        case 1:
          return '自动结算';

        case 2:
          return '线下结算';

        default:
          return '未知';
      }
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleTime: function handleTime(val) {
      console.log("sss", val);

      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    remoteAgent: function remoteAgent(query) {
      var _this8 = this;

      console.log("agent remote");

      if (query !== "") {
        this.agentLoading = true;
        setTimeout(function () {
          _this8.agentLoading = false;
          var data = {
            keyword: query
          };
          fetchAgentList(data).then(function (res) {
            console.log(res);
            _this8.agentOptions = res.data;
          });
        }, 100);
      } else {
        this.agentOptions = [];
      }
    },
    transBillState: transBillState
  }
};